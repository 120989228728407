import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  viewBox: "0 0 32 32",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#212529",
      d: "M5.3335 12.0002V9.3335H26.6668V12.0002H5.3335ZM5.3335 17.3335V14.6668H26.6668V17.3335H5.3335Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#212529",
      d: "M5.3335 17.3335V14.6668H26.6668V17.3335H5.3335ZM5.3335 22.6668V20.0002H16.0002H26.6668V22.6668H5.3335Z"
    }, null, -1)
  ])))
}
export default { render: render }